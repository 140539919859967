<template>
  <div :class="`review ${classes}`">
    <img
      class="review__img"
      :src="require(`../../assets/img/${img}`)"
      :alt="`${fullname} - zdjęcie`"
    />
    <div class="review__fullname-container">
      <span class="review__fullname">{{ fullname }}</span>
      <five-star></five-star>
    </div>
    <p class="review__text">
      <slot></slot>
    </p>
    <div class="review__background"></div>
  </div>
</template>

<script>
import FiveStar from "./FiveStar.vue";

export default {
  components: {
    FiveStar,
  },
  props: {
    img: String,
    fullname: String,
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.review {
  @apply grid justify-items-center p-10 pt-0 pb-0 gap-2 content-center;
  width: 410px;
  height: 510px;

  &__img {
    @apply z-10 rounded-lg;
  }
  &__fullname {
    &-container {
      @apply w-full grid grid-flow-col items-center bg-3A text-white rounded-md pl-3 pr-3 h-min z-10;
      background-color: rgba(58, 58, 58, 0.7);
      @media (max-width: 282px) {
        @apply pr-0;
      }
    }
  }
  &__text {
    @apply text-center z-10;
  }
  &__background {
    @apply absolute border-3 border-black bg-white rounded-xl w-full bottom-0;
    height: 352.8px;

    @media (max-width: 853px) {
      @apply hidden;
    }
  }
}
</style>
