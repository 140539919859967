<template>
  <div class="five-star">
    <span class="five-star__label">5,0</span>
    <img src="../../assets/img/icon_star.svg" alt="Gwiazdek" class="five-star__icon" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.five-star {
  @apply rounded-full grid grid-flow-col gap-1 items-center text-white bg-3A w-max p-1.25 pr-3 pl-3 justify-self-end;
  &__icon {
    @apply justify-self-end;
  }
}
</style>
