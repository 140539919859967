<template>
  <div class="start">
    <section class="introduction">
      <div class="introduction__content">
        <h1 class="introduction__title">CrossFit Black Star</h1>
        <p class="introduction__text">
          Jesteś osobą, która chce zacząć swoją przygodę ze sportem lub szukasz czegoś
          nowego? Jeśli tak to trafiłeś w dobre miejsce.
        </p>
        <p class="introduction__text">
          Jesteśmy doświadczonym zespołem pasjonatów, którzy pomogą Ci osiągnąć postawione
          sobie cele.
        </p>
      </div>
    </section>
    <section class="what-is-that-crossfit">
      <div class="what-is-that-crossfit__def">
        <h2 class="what-is-that-crossfit__title">Czym jest Crossfit?</h2>
        <p class="what-is-that-crossfit__text">
          CrossFit to wytrzymałościowo-siłowy system treningowy, którego głównym celem
          jest holistyczny rozwój ogólnej sprawności fizycznej. CrossFit to alternatywny
          system treningowy dla znudzonych ćwiczeniami w zwykłej siłowni lub na fitnessie.
          Poniżej odsyłam do filmu, który tłumaczy dokładniej czym jest crossfit. <br />
          <text-links
            :IsExternalLink="true"
            :href="'https://www.youtube.com/watch?v=mlVrkiCoKkg&feature=youtu.be'"
          >
            https://www.youtube.com/watch?v=mlVrkiCoKkg&feature=youtu.be
          </text-links>
        </p>
      </div>
      <div class="what-is-that-crossfit__call-to-action">
        <img
          src="../assets/img/webp/what_is_crossfit_img.webp"
          alt=""
          class="what-is-that-crossfit__img"
        />
        <base-button
          classes="dark-btn"
          type="router-link"
          to="/subpages/contact.html"
        >
          Umów Spotkanie
        </base-button>
      </div>
    </section>
    <section class="about-us">
      <div class="about-us__img-and-title">
        <h2 class="about-us__title">O Nas</h2>
        <p class="about-us__text about-us__text--media">
          Jesteśmy afiliowanym boxem w samym centrum Wrocławia. Dysponujemy powierzchnią
          około 400m<sup>2</sup>. Posiadamy wysokiej jakości sprzęt oraz certyfikowanych
          trenerów. Znajdziecie nas przy ulicy Komandorskiej 18. Nie obiecujemy, że będzie
          łatwo, ale pod okiem naszych prowadzących każdy może spróbować CrossFit’u–
          niezależnie od wieku, płci, kondycji czy doświadczenia treningowego.
        </p>
        <img src="../assets/img/webp/about-us-img.webp" alt="" class="about-us__img" />
      </div>
      <div class="about-us__call-to-action">
        <p class="about-us__text">
          Jesteśmy afiliowanym boxem w samym centrum Wrocławia. Dysponujemy powierzchnią
          około 400m<sup>2</sup>. Posiadamy wysokiej jakości sprzęt oraz certyfikowanych
          trenerów. Znajdziecie nas przy ulicy Komandorskiej 18. Nie obiecujemy, że będzie
          łatwo, ale pod okiem naszych prowadzących każdy może spróbować CrossFit’u–
          niezależnie od wieku, płci, kondycji czy doświadczenia treningowego.
        </p>
        <base-button classes="dark-btn" type="router-link" to="/subpages/aboutUs.html">
          Czytaj więcej
        </base-button>
      </div>
    </section>
    <section class="how-to-start">
      <h2 class="how-to-start__title">Jak zacząć ?</h2>
      <p class="how-to-start__text">
        Jeśli dotychczas nie miałeś styczności z CrossFitem, to mamy dla Ciebie zajęcia
        Fundamentals. Jest to miesięczny kurs 8 spotkań, który przeprowadzi Cię krok po
        kroku przez techniki wykonywania ćwiczeń. Dowiesz się na czym polega filozofia
        treningu CrossFit oraz poznasz zasady funkcjonowania w naszym klubie. Po
        ukończeniu kursu możesz korzystać ze wszystkich zajęć dostępnych w grafiku.
      </p>
      <base-button
        classes="light-btn"
        type="external-link"
        to="https://wod.guru/kup-karnet/crossfitblackstar"
      >
        Zacznij Trenować
      </base-button>
    </section>
    <section class="you-are-in-a-good-hands">
      <h2 class="you-are-in-a-good-hands__title">Jesteś w dobrych rękach</h2>
      <p class="you-are-in-a-good-hands__text">
        Zaczynając trenować z nami wchodzisz w nasze małe społeczeństwo. Ostrzegamy, że
        doświadczenia treningowe bywają uzależniające. Od 2016 roku tworzymy klub z pasją
        i zaangażowaniem. Nasi trenerzy zarazili tym sportem już setki klubowiczów.
        Dołączając do nas, stajesz się jednym z nas!
      </p>
      <fiframe
        class="you-are-in-a-good-hands__video"
        src="https://www.youtube.com/embed/Vb96MbGAw1M"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        ref="fiframe"
      ></fiframe>
      <base-button
        classes="dark-btn"
        type="external-link"
        to="https://wod.guru/kup-karnet/crossfitblackstar"
        >Kup Karnet</base-button
      >
    </section>
    <section class="sing-up">
      <h2 class="sing-up__title">Zapisz się</h2>
      <img class="sing-up__img" src="../assets/img/webp/sing_up_img.webp" />
      <p class="sing-up__text">
        Profesjonalna, certyfikowana kadra instruktorska, najwyższej jakości sprzęt,
        znakomita lokalizacja i ponad 400m2 powierzchni. Komandorska 18, Wrocław
      </p>
      <base-button
        type="external-link"
        classes="dark-btn sing-up__btn"
        to="https://wod.guru/kup-karnet/crossfitblackstar"
      >
        Dołącz do nas już dziś!
      </base-button>
    </section>
    <section class="reviews">
      <h2 class="reviews__title">Opinie naszych podopiecznych</h2>
      <review
        classes="reviews__review reviews__review--one"
        img="webp/czeslaw_kciuk.webp"
        fullname="Czesław Kciuk"
      >
        Trenuje tu ponad 2 lata, forma jest na wysokim poziomie, trener Artur przygotował
        mnie do 3 walk zawodowych i kilku amatorskich. Polecam z całego serca. 👍
      </review>
      <review
        classes="reviews__review reviews__review--two"
        img="webp/krzysztof_czyzkiewicz.webp"
        fullname="Krzysztof Czyżkiewicz"
      >
        Profesjonalizm i ogromna wiedza prowadzących zajecia. Zajęcia dla początkujących i
        dzików :). Pasja i miejsce z klimatem. Jedyne czego potrzebujesz to odrobina czasu
        i motywacji :) polecam.
      </review>
      <base-button
        classes="dark-btn reviews__btn"
        type="external-link"
        to="https://www.google.pl/maps/place/CrossFit+Black+Star/@51.0990664,17.0284907,17z/data=!4m7!3m6!1s0x470fc26e5954d4d5:0xbb2d862aab28219b!8m2!3d51.0990734!4d17.0306289!9m1!1b1"
      >
        Zobacz wszystko
      </base-button>
    </section>
  </div>
</template>

<script>
import Review from "../components/main-components/Review.vue";

export default {
  components: {
    Review,
  },
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }

    this.$store.state.IsFiframeTransform = false;
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/start.scss";
</style>
